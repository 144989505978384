<template>
  <div>
    <form-wizard
      color="#9600ff"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Ajouter"
      back-button-text="Précédant"
      next-button-text="suivant"
      class="wizard-vertical mb-3"
      @on-complete="addProject"
    >
      <!-- account datails tab -->
      <tab-content title="Project informations">
       <b-row>
  <b-col cols="12" class="mb-2">
    <h3 class="mb-0">{{ $t('Project') }}</h3>
    <small class="text-muted">
      {{ $t('General_information_about_the_project') }}
    </small>
  </b-col>
  <b-col md="12">
    <b-form-group :label="$t('Project_name')" label-for="v-nom">
      <b-form-input
        id="v-nom"
        :placeholder="$t('Project_name')"
        v-model="project.name"
      />
    </b-form-group>
  </b-col>
  <b-col md="12">
    <b-form-group :label="$t('Tags')" label-for="tag">
      <v-select
        v-model="project.tags"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="tagsOptions"
        :reduce="(option) => option.value"
        input-id="tags"
        label="label"
        multiple
      />
      <template #option="{ label }">
        <span class="ml-50 d-inline-block align-middle">
          {{ label }}</span
        >
      </template>
    </b-form-group>
  </b-col>
  <b-col md="12">
    <b-form-group :label=" $t('Description')" label-for="v-description">
      <b-form-textarea
        id="v-description"
        :placeholder="$t('Project_description')"
        rows="3"
        v-model="project.description"
      />
    </b-form-group>
  </b-col>
</b-row>

      </tab-content>

      <!-- personal info tab -->
      <tab-content title="Project details">
      <b-row>
  <b-col cols="12" class="mb-2">
    <h5 class="mb-0">{{ $t('Project_details') }}</h5>
    <small class="text-muted">{{ $t('Detail_project') }}</small>
  </b-col>
  <b-col md="12" style="margin-bottom: 15px">
    <b-form-group :label="$t('Assignee')" label-for="assignee">
      <v-select
        v-model="users"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="usersOptions"
        label="name"
        class="assignee-selector"
        input-id="assignee"
        multiple
      >
      </v-select>
    </b-form-group>
  </b-col>
</b-row>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { heightTransition } from "@core/mixins/ui/transition";
import axios from "axios";
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BFormFile,
  BImg,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
  BFormRadioGroup,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BFormRadioGroup,
    BForm,
    BButton,
    BImg,
    BFormFile,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormTextarea,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      tagsOptions: [],
      usersOptions: [],
      optionsRadio: [
        { text: "List", value: "first", disabled: false },
        { text: "Table", value: "second", disabled: false },
      ],
      // items: [
      //   {
      //     id: 1,
      //     value: "",
      //   },
      // ],
      nextTodoId: 2,
      optionCategorie: [],
      optionSubCategorie: [],
      users: [],
      project: {
        user: JSON.parse(localStorage.getItem("userData"))._id,
        name: "",
        description: "",
        tags: [],
        users: [],
        image: "",
      },
    };
  },

  // mounted() {
  //   this.initTrHeight();
  // },
  // destroyed() {
  //   // window.removeEventListener("resize", this.initTrHeight);
  // },
  created() {
    // window.addEventListener("resize", this.initTrHeight);
    this.getTags();
    this.getUsers();
  },
  methods: {
    getTags() {
      axios
        .get(
          "https://backendapinodejs.timecheckit.com/api/tags?user=" +
            JSON.parse(localStorage.getItem("userData"))._id
        )
        .then((response) => {
          console.log("tags", response.data.data);
          response.data.data.forEach((element) => {
            this.tagsOptions.push({
              label: element.name,
              value: element._id,
            });
          });
        })
        .catch((error) => {
          this.errorMessage = error.message;
        });
    },
    getUsers() {
      axios
        .get(
          "https://backendapinodejs.timecheckit.com/api/users?id=" +
            JSON.parse(localStorage.getItem("userData"))._id
        )
        .then((response) => {
          response.data.data.friendList.forEach((element) => {
            this.usersOptions.push({
              name: element.name,
              id: element._id,
            });
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    // repeateAgain() {
    //   this.items.push({
    //     id: (this.nextTodoId += this.nextTodoId),
    //   });

    //   this.$nextTick(() => {
    //     this.trAddHeight(this.$refs.row[0].offsetHeight);
    //   });
    // },
    // removeItem(index) {
    //   this.items.splice(index, 1);
    //   this.trTrimHeight(this.$refs.row[0].offsetHeight);
    // },
    // initTrHeight() {
    //   this.trSetHeight(null);
    //   this.$nextTick(() => {
    //     this.trSetHeight(this.$refs.form.scrollHeight);
    //   });
    // },
    // openUploadModal() {
    //   window.cloudinary
    //     .openUploadWidget(
    //       { cloud_name: "dmdd546ps", upload_preset: "jhyzlocs" },
    //       (error, result) => {
    //         if (!error && result && result.event === "success") {
    //           console.log("Done uploading..: ", result);
    //           this.url = result.info.url;
    //           this.publicId = result.info.public_id;
    //           this.product.image = this.publicId;
    //         }
    //       }
    //     )
    //     .open();
    // },
    // imgProduct() {
    //   return (
    //     "https://res.cloudinary.com/dmdd546ps/image/upload/v1615691410/" +
    //     this.product.image
    //   );
    // },
    addProject() {
      // this.project.users = [];
      // this.items.forEach((element) => {
      //   this.project.users.push(element.value);
      // });
      console.log(this.project);
      this.project.users.push(JSON.parse(localStorage.getItem("userData"))._id);
      this.project.invitedUsers = [];
      this.users.forEach((element) => {
        this.project.invitedUsers.push(element.id);
      });
      console.log(this.project);
      axios
        .post("https://backendapinodejs.timecheckit.com/api/projects", this.project)
        .then((response) => {
          console.log(response);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "project bien ajouté",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.$router.push("/apps/projects");
        })
        .catch((error) => {
          this.errorMessage = error.message;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
